<template>
  <v-card
    v-if="data"
    class="mx-auto my-6"
    @click.stop.prevent="$router.push({ name: 'edit-post', params: { postId: data.id } })"
  >
    <v-row>
      <v-col cols="3">
        <v-row no-gutters justify="end" align="start" style="height: 100%;" class="my-3">
          <v-col cols="2" class="d-flex flex-column align-end justify-end">
            <v-checkbox
              v-if="publish"
              v-show="!published"
              class="ma-0 pa-0"
              :value="published"
              @change="value => $emit('onPublishChecked', { ...data, published: value })"
              @click.stop.prevent
            />
          </v-col>
          <v-col cols="10">
            <v-img
              v-if="data.banner"
              :src="data.banner"
              contain
            />
            <v-img
              v-else
              src="@/assets/no_image.svg"
              contain
              style="border: 1px solid #d8d8d8;"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" class="d-flex flex-column align-space-between">
        <v-row no-gutters align="center" class="my-3">
          <v-chip
            :color="labelInfo.color"
            outlined
            label
            dark
          >
            <span v-text="labelInfo.label" />
          </v-chip>
          <h3
            class="text-truncate pl-6"
            style="max-width: calc(80% - 8rem);"
            v-text="data.title"
          />
          <span class="ml-2" style="max-width: 8rem;" v-text="formattedDate" />
          <div class="ml-auto">
            <v-icon color="primary">
              thumb_up_off_alt
            </v-icon>
            <span class="my-0 mx-2" v-text="data.likes || 0" />
          </div>
        </v-row>
        <v-row no-gutters>
          <span class="text-wrap caption" style="max-height: 3.5rem; overflow-y: hidden;" v-html="description" />
        </v-row>
        <v-row no-gutters>
          <div
            v-for="(group, i) in formattedUsers"
            :key="i"
          >
            <v-chip v-if="group.items.length" small :ripple="false" class="mr-1">
              <v-icon left size="20">
                {{ group.icon }}
              </v-icon>
              <multiple-labels :labels="group.items" />
            </v-chip>
          </div>
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row no-gutters class="my-3">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                <v-icon>
                  more_vert
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-item @click="$emit('onPublish', data)">
              <v-list-item-title>{{ $t('common.publish') }}</v-list-item-title>
            </v-list-item> -->
              <v-list-item v-if="data.active" @click="$emit('onDuplicate', data)">
                <v-list-item-title>{{ $t('common.duplicate') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="data.active" @click="$emit('onArchive', data)">
                <v-list-item-title>{{ $t('common.archive') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-else-if="!data.active" @click="$emit('onUnarchive', data)">
                <v-list-item-title>{{ $t('common.unarchive') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$emit('onDelete', data)">
                <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'PostsCard',
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    data: { type: Object, required: true },
    publish: { type: Boolean, default: false },
  },
  data() {
    return {
      published: false,
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ players: 'projectUsers/rawPlayers' }),
    ...mapGetters({ coaches: 'projectUsers/coaches' }),
    ...mapGetters({ parents: 'academy/parents' }),
    ...mapGetters({ managers: 'academy/managers' }),
    ...mapGetters({ submanagers: 'academy/submanagers' }),
    ...mapGetters('project', ['isGuild']),
    parentsAndCoachesOnly: ({ data }) => data.userSources?.excludeTeamPlayers,
    labelInfo: ({ data, published, formattedStatus }) => formattedStatus(data, published),
    description({ data }) {
      return data?.text?.length > 150 ? `${data.text.substring(0, 200)}...` : data?.text
    },
    roles() {
      return [
        {
          id: 'coach',
          name: this.$t('user.roles.coaches'),
        },
        {
          id: 'player',
          name: this.$t('user.roles.players'),
        },
        {
          id: 'parent',
          name: this.$t('user.roles.parents'),
        },
        {
          id: 'manager',
          name: this.$t('user.roles.managers'),
        },
        {
          id: 'submanager',
          name: this.$t('user.roles.submanagers'),
        },
      ]
    },
    selectedRoles: ({ data }) => data.userSources?.roles,
    allProjectUsers: ({ data }) => !data.userSources?.teamIds?.length,
    filteredTeams({ teams, data }) {
      return teams
        ?.filter(team => data.userSources?.teamIds?.includes(team.id))
           ?? []
    },
    formattedTeams: ({ filteredTeams }) => filteredTeams.map(team => team.fullTeamName),
    formattedPlayers: ({ players, formatRoles }) => formatRoles(players, 'player'),
    formattedParents: ({ parents, formatRoles }) => formatRoles(parents, 'parent'),
    formattedCoaches: ({ coaches, formatRoles }) => formatRoles(coaches, 'coach'),
    formattedManagers: ({ managers, formatRoles }) => formatRoles(managers, 'manager'),
    formattedSubmanagers: ({ submanagers, formatRoles }) => formatRoles(submanagers, 'submanager'),
    formattedUsers({ formattedTeams, formattedPlayers, formattedParents, formattedCoaches, formattedManagers, formattedSubmanagers, formatUser }) {
      return [
        {
          items: formattedTeams,
          icon: 'sports_basketball',
        },
        {
          items: formattedPlayers.map(formatUser),
          icon: 'school',
        },
        {
          items: formattedParents.map(formatUser),
          icon: 'group',
        },
        {
          items: formattedCoaches.map(formatUser),
          icon: 'sports',
        },
        {
          items: formattedManagers.map(formatUser),
          icon: 'admin_panel_settings',
        },
        {
          items: formattedSubmanagers.map(formatUser),
          icon: 'verified_user',
        },
      ]
    },
    formattedDate({ data, published }) {
      return data?.publishedAt?.seconds
        && data?.published
        && published
        ? `(${DateTime.fromSeconds(data.publishedAt.seconds).toISODate()})`
        : ''
    },
  },
  watch: {
    publish() {
      this.published = this.data.published
    },
  },
  created() {
    const { data } = this
    this.published = data?.published
  },
  methods: {
    formattedStatus(data, published) {
      const { active } = data
      let info
      switch (true) {
      case !active:
        info = { color: this.$vuetify.theme.themes.light.archived, label: this.$t('filters.archived') }
        break
      case published:
        info = { color: this.$vuetify.theme.themes.light.upToDate, label: this.$t('common.published') }
        break
      case !published:
        info = { color: this.$vuetify.theme.themes.light.unpublished, label: this.$t('common.unpublished') }
        break
      default:
        break
      }
      return info
    },
    formatUser: player => `${player?.firstName} ${player?.lastName}`,
    formatRoles(users, type) {
      const { organizationId, projectId, filteredTeams, selectedRoles, allProjectUsers } = this
      if (!this.isGuild && selectedRoles?.includes(type)) {
        if (allProjectUsers || ['parent', 'manager', 'submanager'].includes(type)) return users
        const teamIds = filteredTeams.map(team => team.id)
        if (teamIds) {
          return users
            ?.filter(user => Object.keys(
              user?.roles?.byProperty[organizationId]?.byProject[projectId]?.byTeam ?? {},
            ).some(teamId => teamIds.includes(teamId))) ?? []
        }
      }
      return []
    },
  },
}
</script>

<style lang="scss">

.custom .v-input__slot {
  max-width: fit-content;
}

.custom .v-input__slot::before {
  border-style: none !important;
}

.custom .v-input__slot::after {
  border-style: none !important;
}

.v-chip::before {
  display: none;
}

.v-image {
  border-radius: 4px !important;
}
</style>
